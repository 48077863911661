import GATSBY_COMPILED_MDX from "/opt/build/repo/content/pages/markdown.mdx";
import React from 'react';
import {graphql} from 'gatsby';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MdxRender from 'components/mdx-render';
import Meta from 'components/meta';
import Wrapper from 'components/wrapper';
function MdxDefault({location, children, data: {mdx}}) {
  const {title, author, description} = mdx?.frontmatter ?? ({});
  return React.createElement(Wrapper, {
    location: location,
    variant: "page",
    wrapperClass: "d-flex flex-column"
  }, React.createElement(Meta, {
    title: title,
    author: author,
    description: description
  }), React.createElement(Row, {
    className: "py-3 justify-content-center"
  }, React.createElement(Col, null, React.createElement(MdxRender, null, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MdxDefault, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        author
        description
        title
      }
    }
  }
`;
